.App {
  text-align: center;
}

ul {
  list-style:  outside none;
  padding: 0;
}

.progress {
  margin-bottom: 15px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.row {
  display: flex;
  justify-content: center;
}

.recentCards{
  display: flex;
  justify-content: center;
  width: 25%;
  flex-wrap: wrap;
  position: absolute;
  top: 10%;
  right: 5%
}

.recentCardsBySuits{
  display: flex;
  justify-content: center;
  width: 25%;
  flex-wrap: wrap;
  position: absolute;
  top: 10%;
  left: 5%
}

.reservationsField {
  justify-content: flex-start;
}

.navigation{
  display: flex;
  justify-content: space-around;
}
.navigationMobile{
  display: none;
}

@media screen and (max-width: 665px){
  .navigation{
    display: none;
  }

  .navigationMobile{
    display: block;
  }
}